'use client';
import { useState } from 'react';
import NextLink from 'next/link';
import createHref from '@utils/url/createHref';
import { useRouter } from 'next/navigation';

type Props = {
  href: string | null | undefined;
  locale?: string;
  className?: string;
} & React.ComponentProps<typeof NextLink>;

export default function PrefetchLink({ href, ...rest }: Props) {
  const router = useRouter();
  const [isPrefetched, setIsPrefetched] = useState(false);

  const handleMouseEnter = () => {
    const urlToPrefetch = createHref(href);
    if (urlToPrefetch && !isPrefetched) {
      router.prefetch(urlToPrefetch);
      setIsPrefetched(true);
    }
  };

  return (
    <NextLink
      {...rest}
      href={createHref(href)}
      onMouseEnter={handleMouseEnter}
    />
  );
}
